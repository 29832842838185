export enum ETouDocs {
  notice = 'Privacy Notice',
  terms = 'Terms of Use',
  policy = 'Privacy Policy'
}

const {
  REACT_APP_PRIVACY_NOTICE_URL,
  REACT_APP_TERMS_OF_SERVICE_URL,
  REACT_APP_PRIVACY_POLICY_URL
} = window._env_

export const touDocsUrls = {
  [ETouDocs.notice]: REACT_APP_PRIVACY_NOTICE_URL,
  [ETouDocs.terms]: REACT_APP_TERMS_OF_SERVICE_URL,
  [ETouDocs.policy]: REACT_APP_PRIVACY_POLICY_URL
}

export const TOU_LINKS_COUNT = Object.keys(touDocsUrls).length
