import React from 'react'
import classnames from 'classnames'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export interface ITouLinkProps {
  title: string
  destinationURL: string
  state?: 'success' | 'danger' | 'default'
  clickHandler: React.MouseEventHandler<HTMLAnchorElement>
}

const TouLink: React.FC<ITouLinkProps> = ({
  title,
  destinationURL,
  state = 'default',
  clickHandler
}) => {
  const rowCls = classnames('g-0', {
    'text-primary': state === 'default',
    'text-success': state === 'success',
    'text-danger': state === 'danger'
  })
  return (
    <Row className={rowCls}>
      <Col className='flex-grow-0'>
        <i
          className='bi bi-check-lg pe-3 fs-5'
          style={{ visibility: state === 'success' ? 'visible' : 'hidden' }}
        />
      </Col>
      <Col>
        <a
          id={title}
          href={destinationURL}
          target='_blank'
          rel='noopener noreferrer'
          className='tou-modal__link'
          onClick={clickHandler}
          title={destinationURL}>
          {title}
        </a>
        <i className='bi ps-1 bi-link-45deg' />
      </Col>
    </Row>
  )
}

export default TouLink
