import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { connect } from 'react-redux'

import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import { acceptTOSandPrivacyPolicy } from '../../actions/user.action'
import { revokeAuthenticationToken } from '../../actions/auth.action'
import { logout } from '../../helpers'

import { GlobalState } from '../../reducers'
import { AppDispatch } from '../../store'

import TouLink from './TouLink'
import { ETouDocs, touDocsUrls, TOU_LINKS_COUNT } from './config'

export type TTosModalProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export const TouModal = (props: TTosModalProps) => {
  // actions
  const { dAcceptTerms, dRevokeToken, termsOfServiceAccepted } = props
  // state tracking dynamic elements
  const [visitedLinks, setVisitedLinks] = useState<string[]>([])
  const [confirmed, setConfirmed] = useState(false)
  const [error, setError] = useState(false)

  const allLinksVisited = visitedLinks.length === TOU_LINKS_COUNT
  const canProceed = allLinksVisited && confirmed

  useEffect(() => {
    if (canProceed) setError(false)
  }, [canProceed])

  const linkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const { id: linkId } = e.currentTarget
    if (visitedLinks.includes(linkId)) return

    const links = [...visitedLinks, linkId]
    setVisitedLinks(links)
  }

  const handleConfirmation = () => {
    if (allLinksVisited) {
      setConfirmed(!confirmed)
    } else {
      setError(true)
    }
  }

  const acceptTOS = () => {
    if (canProceed) {
      const todayDate = dayjs().format('YYYY-MM-DD')
      dAcceptTerms(todayDate)
    } else {
      setError(true)
    }
  }
  /**
   * revoke token endpoint fails randomly in diff envs
   * finally ensures redirect to login page happens regardless of succes or error
   * @returns void
   */
  const declineTOS = async () => {
    try {
      await dRevokeToken()
    } finally {
      logout()
      return
    }
  }

  const getLinkState = (type: ETouDocs) => {
    return visitedLinks.includes(type)
      ? 'success'
      : error
      ? 'danger'
      : 'default'
  }

  return (
    <Modal show={!termsOfServiceAccepted} className='tou-modal' size='lg'>
      <Modal.Header className='tou-modal__header'>
        <h1 className='tou-modal__title'>
          Acknowledgement of Terms and Policies
        </h1>
      </Modal.Header>
      <Modal.Body className='tou-modal__body'>
        <Container className='p-0'>
          <div className='tou-modal__body-text pb-2'>
            Please review each document by following the links below. A green
            check mark will appear once each document is read.
          </div>
          {Object.keys(ETouDocs).map((type) => (
            <TouLink
              key={ETouDocs[type]}
              title={ETouDocs[type]}
              state={getLinkState(ETouDocs[type])}
              destinationURL={touDocsUrls[ETouDocs[type]]}
              clickHandler={linkClick}
            />
          ))}
          <Row className='g-0 pt-3'>
            <Col className='flex-grow-0'>
              <Form.Check
                className='tou-modal__checkbox pe-3'
                name='agree-to'
                id='tou-modal-checkbox'
                checked={confirmed}
                isInvalid={error && !confirmed}
                onChange={handleConfirmation}
              />
            </Col>
            <Col>
              <div className='tou-modal__body-text'>
                I have received, read and agreed to the terms outlined in the
                documents.
              </div>
            </Col>
          </Row>

          <Row
            className='g-0 pt-3'
            style={{ visibility: error ? 'visible' : 'hidden' }}
            role='alert'>
            <Col className='flex-grow-0'>
              <i className='bi bi-exclamation-triangle fs-5 text-danger pe-3' />
            </Col>
            <Col>
              <span className='tou-modal__body-text text-danger'>
                Attention: You must review all documents and check the
                acknowledgment box before continuing.
              </span>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className='tou-modal__footer'>
        <Button size='lg' variant='outline-primary' onClick={declineTOS}>
          Decline
        </Button>
        <Button size='lg' variant='primary' onClick={acceptTOS}>
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  dRevokeToken: () => dispatch(revokeAuthenticationToken()),
  dAcceptTerms: (date) => dispatch(acceptTOSandPrivacyPolicy(date))
})

const mapStateToProps = (store: GlobalState) => {
  return { termsOfServiceAccepted: store.user.termsOfServiceAccepted }
}

export default connect(mapStateToProps, mapDispatchToProps)(TouModal)
